<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Funcionários - Edição</h2>
        </v-flex>
        <v-flex xs2 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'employees'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-edit-employee :employee="employee" :update="true"></form-edit-employee>
</span>
</template>

<script>
import FormEditEmployeeComponent from "./partial/FormEditEmployeeComponent";

export default {
    name: "EditEmployeeComponent",
    created() {
        this.loadEmployee()
    },
    props: {
        empl_id: {
            require: true
        }
    },
    data() {
        return {
            employee: {
                empl_id: '',
                company_id: '',
                department_id: '',
                empl_login: '',
                empl_email: '',
                empl_password: '',
                empl_nome: '',
                empl_datanasc: '',
                empl_foto: '',
                empl_prefixo: '',
                empl_fone: '',
                empl_celular: '',
                empl_nivel: '1',
                empl_status: false,
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadEmployee() {
            this.$store.dispatch('loadEmployee', this.empl_id)
                .then((response) => {this.employee = response})
                .catch(error => {
                    this.$swal({
                        position: "center",
                        type: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formEditEmployee: FormEditEmployeeComponent
    }
};
</script>

<style scoped>

</style>
