<template>
<span>
    <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
        <v-container fluid>
            <v-row>
                <v-col cols="10" xs="12" sm="12" md="8" lg="10" xl="10">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-select v-model="employee.departamento.dept_id" :items="departments" item-text="dept_nome" item-value="dept_id" label="Selecione um departamento..." :rules="rules.departmentRules" required outlined></v-select>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="employee.empl_nome" :rules="rules.nomeRules" label="Nome" outlined required></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="employee.email" :rules="rules.emailRules" label="Email" outlined required></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field v-model="employee.password" :append-icon="show ? 'visibility' : 'visibility_off'" :rules="rules.passwordRules" :type="show ? 'text' : 'password'" name="password" label="Senha" hint="Mínimo de 8 caracteres" counter @click:append="show = !show" outlined required></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-text-field v-model="employee.empl_prefixo" :rules="rules.prefixoRules" label="DDD" v-mask="'##'" masked="false" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-text-field v-model="employee.empl_fone" :rules="rules.foneRules" label="Telefone" v-mask="'####-####'" outlined required></v-text-field>
                        </v-col>

                        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <v-text-field v-model="employee.empl_celular" :rules="rules.celularRules" label="Celular" v-mask="'#-####-####'" outlined></v-text-field>
                        </v-col>

                        <v-col xs12>
                            <v-switch color="lime" v-model="employee.empl_status" label="Status"></v-switch>
                        </v-col>

                        <v-col xs12 xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                            <v-btn class="btn-salvar" :disabled="!valid" color="success" @click="onSubmit">
                                <v-icon dark class="mdi mdi-check"></v-icon>{{btnLabel}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="2" xs="12" sm="12" md="4" lg="2" xl="2" class="justify-center">
                    <v-col v-if="preview || employee.empl_foto">
                        <v-card>
                            <v-img v-if="preview" class="img-preview" :src="preview">
                            </v-img>

                            <v-img v-if="employee.empl_foto && !preview" class="img-preview" :src="`${base_url}upload/employee/${employee.empl_foto}`">
                            </v-img>

                            <v-card-actions class="justify-center">
                                <v-btn icon>
                                    <v-icon @click="$refs.file.click()">cloud_upload</v-icon>
                                </v-btn>
                                <v-btn icon>
                                    <v-icon @click.prevent="removePreview">highlight_off</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <v-col class="justify-center">
                        <v-btn v-if="!preview && !employee.empl_foto" color="blue-grey" class="btn-upload white--text" @click="$refs.file.click()">
                            <v-icon class="mdi mdi-cloud-upload mr-2"></v-icon> Adicionar foto
                        </v-btn>
                        <input type="file" ref="file" style="display:none;" id="files" @change="onFileChange" />
                    </v-col>
                </v-col>
            </v-row>

        </v-container>

    </v-form>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../../config/configs'

export default {
    props: {
        employee: {
            require: false,
            type: Object | Array,
            default: () => {
                return {
                    empl_id: '',
                    department_id: '',
                    login: '',
                    password: '',
                    email: '',
                    empl_nome: '',
                    empl_datanasc: '',
                    empl_foto: '',
                    empl_prefixo: '',
                    empl_fone: '',
                    empl_celular: '',
                    empl_nivel: '1',
                    empl_status: false,
                }
            }
        },
        update: {
            require: false,
            type: Boolean,
            default: false
        },
        btnLabel: {
            require: false,
            type: String,
            default: 'Salvar'
        },
        require: true,
    },
    created() {
        this.$store.dispatch('loadDepartmentsActive');
    },
    computed: {
        departments() {
            return this.$store.state.departments.active.data;
        }
    },
    name: "FormEmployeeComponent",

    data() {
        return {
            valid: true,
            rules: {
                nomeRules: [
                    v => !!v || 'Campo Nome é obrigatório',
                    v => (v && v.length <= 200) || 'Nome precisa ter ate 200 Caracteres'
                ],
                departmentRules: [
                    v => !!v || 'Campo Departamento é obrigatório',
                ],
                emailRules: [
                    v => !!v || 'Campo E-Mail é obrigatório',
                    v => /.+@.+/.test(v) || 'E-mail precisa ser válido'
                ],
                prefixoRules: [
                    v => !!v || 'Campo Prefixo é obrigatório',
                    v => (v && v.length == 2) || 'Prefixo deve ter 2 caracteres'
                ],
                foneRules: [
                    v => !!v || 'Campo Telefone é obrigatório',
                ],
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            show: false,
            base_url: URL_BASE
        }
    },
    methods: {
        onSubmit() {

            if (this.$refs.form.validate()) {
                const action = this.update ? 'updateEmployee' : 'storeEmployee';
                const msg = this.update ? 'Dados atualizados com sucesso' : 'Cadastro efetuado com sucesso';

                const formData = new FormData();

                if (this.upload !== null)
                    formData.append('empl_foto', this.upload);

                formData.append('empl_id', this.employee.empl_id);
                formData.append('department_id', this.employee.departamento.dept_id);
                formData.append('login', this.employee.email);
                formData.append('password', this.employee.password);
                formData.append('email', this.employee.email);
                formData.append('empl_nome', this.employee.empl_nome);
                formData.append('empl_datanasc', this.employee.empl_datanasc);
                formData.append('empl_prefixo', this.employee.empl_prefixo);
                formData.append('empl_fone', this.employee.empl_fone);
                formData.append('empl_celular', this.employee.empl_celular);
                formData.append('empl_nivel', this.employee.empl_nivel);
                formData.append('empl_status', this.employee.empl_status);

                this.$store.dispatch(action, formData)
                    .then(() => {
                        this.$swal({
                            position: "center",
                            icon: "success",
                            title: msg,
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.$router.push({
                            name: 'employees'
                        });
                    })
                    .catch(error => {
                        this.$swal({
                            position: "center",
                            icon: "error",
                            title: 'Erro',
                            text: 'Algo deu errado',
                            showConfirmButton: true,
                            timer: 6000
                        });
                        this.errors = error.response.data.errors
                    })
            } else {
                this.$swal({
                    position: "center",
                    icon: "warning",
                    title: 'Alguns campos requer sua atenção',
                    text: 'Verifique os campos em vermelho',
                    showConfirmButton: true,
                    timer: 6000
                });
            }

        },
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files;

            if (!files.length)
                return;

            this.upload = files[0];
            this.fileName = this.upload.name;

            this.previewImage(files[0]);
        },
        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.preview = e.target.result;
            };
            reader.readAsDataURL(file)
        },
        removePreview() {
            this.preview = null;
            this.upload = null;
        },
    },
};
</script>

<style scoped>
.theme--dark.v-sheet {
    background-color: #303030;
    border-color: #303030;
    color: #fff;
    box-shadow: none;
}

.btn-upload,
.btn-salvar {
    margin: 0;
}

/*Also*/
.fileinput-button input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
    filter: 'alpha(opacity=0)';
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.img-preview {
    max-width: 100%;
    height: 120px;
}
</style>
